import AppStoreBtn from '@/public/assets/images/app-store-btn.png';
import AvatarReviewImage from '@/public/assets/images/avatar-review.png';
import ChartIcon from '@/public/assets/images/chart.svg';
import CheckedImage from '@/public/assets/images/checked.svg';
import CheckListIcon from '@/public/assets/images/checklist.svg';
import CustomerAvatar1 from '@/public/assets/images/customer_bach_viet.jpg';
import CustomerAvatar2 from '@/public/assets/images/customer_hoang_thinh.jpg';
import CustomerAvatar4 from '@/public/assets/images/customer_minh_tuan.png';
import CustomerAvatar3 from '@/public/assets/images/customer_viet_hoang.jpg';
import DirectionIcon from '@/public/assets/images/direction.svg';
import EmptyImage from '@/public/assets/images/empty.webp';
import ExpressIcon from '@/public/assets/images/express.svg';
import FeatureIcon from '@/public/assets/images/feature.svg';
import FeatureManageImage from '@/public/assets/images/features-manage.png';
import GgPlayBtn from '@/public/assets/images/gg-play-btn.png';
import HeroBackground from '@/public/assets/images/hero-background.jpg';
import HeroImage from '@/public/assets/images/hero-image.png';
import LoadingImage from '@/public/assets/images/loading.json';
import LogoImage from '@/public/assets/images/Logo.svg';
import LogoWhiteImage from '@/public/assets/images/LogoWhite.svg';
import MessageIcon from '@/public/assets/images/message.svg';
import ThunderIcon from '@/public/assets/images/thunder.svg';
import UtilitiesImage from '@/public/assets/images/utilities-image.png';

export {
  AppStoreBtn,
  AvatarReviewImage,
  ChartIcon,
  CheckedImage,
  CheckListIcon,
  CustomerAvatar1,
  CustomerAvatar2,
  CustomerAvatar3,
  CustomerAvatar4,
  DirectionIcon,
  EmptyImage,
  ExpressIcon,
  FeatureIcon,
  FeatureManageImage,
  GgPlayBtn,
  HeroBackground,
  HeroImage,
  LoadingImage,
  LogoImage,
  LogoWhiteImage,
  MessageIcon,
  ThunderIcon,
  UtilitiesImage,
};
